import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

let originalUri:string = ''; //(window.location.search.split('original_uri=')[1] || '').split('&')[0] || '';

if(!originalUri && originalUri.length <= 0) {
  const urlParams = new URLSearchParams(document.location.search);
  console.log("URL PARAMS: ", urlParams);
  
  originalUri = urlParams.get('original_uri') as any;

  console.log("URL PARAMS: ", urlParams);
  console.log("window.location.search: ", window.location.search);
}
console.log('ORIGINAL_URL: ', originalUri);

if (originalUri && originalUri !== '') {
  localStorage.setItem(
    'original_uri',
    originalUri
  );
  console.log("SAVED original_uri: ", localStorage.getItem('original_uri'));
} else {
  console.error('NO ORIGINAL_URL in index.tsx');
}

root.render(
  <React.StrictMode>
    <h1>...loading</h1>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

