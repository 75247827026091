/* note: this file cannot be named "config" due to an unexplainable NODE_PATH + webpack bug */

// local
import packageJson from '../package.json';

const CLIENT_ID = process.env.CLIENT_ID || 'nike.valiantlabs.rapid-validation-web';
let   ISSUER = process.env.ISSUER || 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.PUBLIC_URL || '';

let REDIRECT_URI = `${window.location.origin}${BASENAME}`;
console.log('ORIG_REDIRECT_URI: ', REDIRECT_URI);

//todo use actual SSM params. not loading from jenkins for some reason
if(window.location.host === 'rapid.vl.nikecloud.com' || window.location.host === 'rapid.nike.com') {
 ISSUER = 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7';
 REDIRECT_URI = 'https://rapid.vl.nikecloud.com/okta/';
}




const HOSTS: { [key: string]: any } = {
  development: 'https://console.platform.test.vl.nikecloud.com/',
  production: 'https://console.platform.vl.nikecloud.com/'
};

console.log('ISSUER: ', process.env.ISSUER);

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
  }
};

export default config;
